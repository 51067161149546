// We need to import the CSS so that webpack will load it.
// The MiniCssExtractPlugin is used to separate it out into
// its own CSS file.
import "../css/app.scss"

// webpack automatically bundles all modules in your
// entry points. Those entry points can be configured
// in "webpack.config.js".
//
// Import deps with the dep name or local files with a relative path, for example:
//
//     import {Socket} from "phoenix"
//     import socket from "./socket"
//
import "phoenix_html"
import {Socket} from "phoenix"
import NProgress from "nprogress"
import {LiveSocket} from "phoenix_live_view"

let Hooks = {}




Hooks.PaymentClickHook = {
  mounted() {
    console.log("payment")

      let main_this = this; 
	  let token = this.el.getAttribute("phx-data-token")
	  let id = this.el.getAttribute("phx-data-id")
	  console.log(token)
    
      Layer.checkout({
	        token: token,
	        accesskey: "37512690-a579-11ea-b879-470196c0eae2"
	    },
	    function(response) {

	    	console.log(response)
	    
	        if (response.status == "captured") {
	                        
	           // response.payment_token_id
	           // response.payment_id
	           main_this.pushEvent("order_status_update", {order_id: id, order_status: "captured", order_response: JSON.stringify(response)})

	        } else if (response.status == "created") {

	           main_this.pushEvent("order_status_update", {order_id: id, order_status: "created", order_response: JSON.stringify(response)})

	        } else if (response.status == "pending") {

	           main_this.pushEvent("order_status_update", {order_id: id, order_status: "pending", order_response: JSON.stringify(response)})

	        } else if (response.status == "failed") {

	           main_this.pushEvent("order_status_update", {order_id: id, order_status: "failed", order_response: JSON.stringify(response)})

	        } else if (response.status == "cancelled") {

	           main_this.pushEvent("order_status_update", {order_id: id, order_status: "cancelled", order_response: JSON.stringify(response)})
	        }
	    },
	    function(err) {
	        //integration errors
	        console.log(err)
	    }
     );

  }
}



let csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute("content")
let liveSocket = new LiveSocket("/live", Socket, {params: {_csrf_token: csrfToken}, hooks: Hooks})

// Show progress bar on live navigation and form submits
window.addEventListener("phx:page-loading-start", info => NProgress.start())
window.addEventListener("phx:page-loading-stop", info => NProgress.done())

// connect if there are any LiveViews on the page
liveSocket.connect()

// expose liveSocket on window for web console debug logs and latency simulation:
// >> liveSocket.enableDebug()
// >> liveSocket.enableLatencySim(1000)
window.liveSocket = liveSocket
